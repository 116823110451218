import React from 'react'
import { useLocation } from 'react-router-dom'

import { SIDEBAR_BUTTONS } from '../../constants/general'
import { pinflagWhiteHorizontalIcon } from '../../constants/icons'
import useSession from '../../hooks/useSession'

import SidebarButton from './SidebarButton'

const Sidebar = () => {
  const currentPath = useLocation().pathname
  const { logout } = useSession()

  const handleClick = () => {
    logout()
  }

  return (
    <div className="relative z-20 flex h-screen flex-col justify-between bg-pinflag-dark shadow-lg">
      <div className="mt-10 mb-4 flex justify-center">
        <img className="h-6" src={pinflagWhiteHorizontalIcon} alt="pw" />
      </div>
      <div className="mt-4 h-fit w-48 select-none">
        <SidebarButton
          data={SIDEBAR_BUTTONS.conditions}
          active={currentPath === SIDEBAR_BUTTONS.conditions.path || currentPath === '/'}
        />
        <SidebarButton
          data={SIDEBAR_BUTTONS.billing}
          active={currentPath === SIDEBAR_BUTTONS.billing.path}
        />
        <SidebarButton
          data={SIDEBAR_BUTTONS.discount}
          active={currentPath === SIDEBAR_BUTTONS.discount.path}
        />

        <SidebarButton
          data={SIDEBAR_BUTTONS.recharge}
          active={currentPath === SIDEBAR_BUTTONS.recharge.path}
        />

        <SidebarButton
          data={SIDEBAR_BUTTONS.courierPrices}
          active={currentPath === SIDEBAR_BUTTONS.courierPrices.path}
        />

        <SidebarButton
          data={SIDEBAR_BUTTONS.deliveryTimes}
          active={currentPath === SIDEBAR_BUTTONS.deliveryTimes.path}
        />

        <SidebarButton
          data={SIDEBAR_BUTTONS.sale}
          active={currentPath === SIDEBAR_BUTTONS.sale.path}
        />
        <SidebarButton
          data={SIDEBAR_BUTTONS.companies}
          active={currentPath === SIDEBAR_BUTTONS.companies.path}
        />
        <SidebarButton
          data={SIDEBAR_BUTTONS.tickets}
          active={currentPath === SIDEBAR_BUTTONS.tickets.path}
        />
        <SidebarButton
          data={SIDEBAR_BUTTONS.refunds}
          active={currentPath === SIDEBAR_BUTTONS.refunds.path}
        />
        <SidebarButton
          data={SIDEBAR_BUTTONS.quoting}
          active={currentPath === SIDEBAR_BUTTONS.quoting.path}
        />
        <SidebarButton
          data={SIDEBAR_BUTTONS.dashboard}
          active={currentPath === SIDEBAR_BUTTONS.dashboard.path}
        />
        <SidebarButton
          data={SIDEBAR_BUTTONS.permissions}
          active={currentPath === SIDEBAR_BUTTONS.permissions.path}
        />
      </div>
      <div
        className="mt-8 flex h-10 cursor-pointer flex-row items-center pl-4 hover:bg-pinflag"
        onClick={handleClick}
        role="button"
        tabIndex="0"
      >
        <b className="z-10 text-sm font-light text-white ">Cerrar Sesión</b>
      </div>
    </div>
  )
}

export default Sidebar
