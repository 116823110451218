import React from 'react'
import ConfirmModal from '../../shared/Modal/ConfirmModal'

import EditNotificationModal from './EditNotificationModal'

const ModalHandler = ({ modalType, modalProps, showModal, notification, loading }) => {
  const { handleClose } = modalProps

  if (!showModal) return null

  switch (modalType) {
    case 'edit':
      return (
        <EditNotificationModal
          notification={notification}
          holdings={modalProps.holdings}
          showModal={showModal}
          handleClose={handleClose}
          handleInputChange={modalProps.handleInputChange}
          shouldFetch={modalProps.handleRefresh}
        />
      )
    case 'confirm':
      return (
        <ConfirmModal
          show={showModal}
          handleSubmit={modalProps.handleSubmit}
          handleClose={handleClose}
          message={modalProps.modalMessage}
          isLoading={loading}
        />
      )
    default:
      return null
  }
}

export default ModalHandler
