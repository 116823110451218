import React, { useCallback, useEffect, useRef, useState } from 'react'
import Table from '../../shared/Table/Table'
import TableRow from '../../shared/Table/TableRow'
import InputForm from '../../shared/Input/InputForm'
import useFetch from '../../../hooks/useFetch'
import {
  createNotification,
  deleteNotification,
  getNotifications
} from '../../../helpers/requests/notificationCenter'
import Loader from '../../shared/Loader'
import ModalHandler from './ModalHandler'

const MainNotifications = ({ holdings }) => {
  const [notifications, setNotifications] = useState([])
  const [editedNotification, setEditedNotification] = useState(null)
  const [modalType, setModalType] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [editingNotificationId, setEditingNotificationId] = useState(null)
  const [tooltipNotificationId, setTooltipNotificationId] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(false)
  const [modalProps, setModalProps] = useState({})
  const [loading, setLoading] = useState(false)

  const initializeData = useCallback((data) => {
    setNotifications(data)
  }, [])

  const { isLoading } = useFetch(getNotifications, initializeData, '')

  const tooltipRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipNotificationId(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (shouldFetch) {
      getNotifications('')
        .then(initializeData)
        .finally(() => setShouldFetch(false))
    }
  }, [shouldFetch, initializeData])

  const howManyHoldings = (notification) =>
    notification.Holdings.length === holdings.length
      ? 'Todas'
      : `${notification.Holdings.length}/${holdings.length}`

  const handleCloseModal = () => {
    setShowModal(false)
    setModalType('')
  }

  const removeNotification = async () => {
    setLoading(true)
    await deleteNotification(editingNotificationId)
    setShouldFetch(true)
    setLoading(false)
    handleCloseModal()
  }

  const handleInputChange = (id, field, value) => {
    const updatedNotifications = notifications.map((notification) =>
      notification.id === id ? { ...notification, [field]: value } : notification
    )
    setNotifications(updatedNotifications)
  }

  const handleOpenModal = async (type, submitFunction, modalMessage = '') => {
    setModalProps({
      holdings,
      modalMessage,
      handleClose: handleCloseModal,
      handleSubmit: submitFunction,
      handleRefresh: setShouldFetch,
      handleInputChange
    })
    setModalType(type)
    setShowModal(true)
  }

  const handleEdit = (notification) => {
    setEditingNotificationId(notification.id)
    setEditedNotification(notification)
    handleOpenModal('edit', () => {})
  }

  const openOptions = (id) => {
    if (tooltipNotificationId === id) {
      setTooltipNotificationId(null)
      setEditingNotificationId(null)
    } else {
      setTooltipNotificationId(id)
      setEditingNotificationId(id)
    }
  }

  const handleCreateNotification = async () => {
    setLoading(true)
    const data = {
      notificationData: {
        info: { name: 'Nueva Notificación', active: false },
        bannerData: { content: '', active: false },
        mailData: { about: '', content: '', active: false },
        popUpData: { content: '', active: false },
        holdingIds: []
      }
    }
    const newNotification = await createNotification(data)
    setLoading(false)
    handleEdit(newNotification)
    setShouldFetch(true)
  }

  return (
    <div className="flex h-screen flex-col bg-light-grey">
      <ModalHandler
        modalType={modalType}
        modalProps={modalProps}
        showModal={showModal}
        notification={editedNotification}
        loading={loading}
      />
      <div className="my-5">
        <div className="mx-10 my-5">
          <div className="mx-10 my-5 flex justify-between">
            <div className="justify-start">Anuncios activos</div>
            <button
              type="button"
              className="justify-end"
              onClick={() =>
                handleOpenModal('confirm', handleCreateNotification, '¿Crear anuncio?')
              }
            >
              Crear anuncio
            </button>
          </div>
          {isLoading && <Loader />}
          {!isLoading && (
            <div>
              <Table
                headers={['Nombre', 'Empresas', 'Banner', 'Mail', 'Pop-up', 'Activa', 'Editar']}
              >
                {notifications?.map((notification) => (
                  <TableRow
                    key={notification.id}
                    onClick={() => {}}
                    items={[
                      <div className="flex items-center">{notification.name || '-'}</div>,
                      <div className="w-12 font-semibold text-medium-gray">
                        {howManyHoldings(notification)}
                      </div>,
                      <InputForm
                        id={`banner_${notification.id}`}
                        size="w-12"
                        name="banner"
                        type="checkbox"
                        checked={notification.Banner?.active}
                        disabled
                      />,
                      <InputForm
                        id={`mail_${notification.id}`}
                        size="w-12"
                        name="mail"
                        type="checkbox"
                        checked={notification.Mail?.active}
                        disabled
                      />,
                      <InputForm
                        id={`popUp_${notification.id}`}
                        size="w-12"
                        name="popUp"
                        type="checkbox"
                        checked={notification.PopUp?.active}
                        disabled
                      />,
                      <InputForm
                        id={`active_${notification.id}`}
                        size="w-12"
                        name="active"
                        type="checkbox"
                        checked={notification.active}
                        disabled
                      />,
                      <div className="relative w-12">
                        {tooltipNotificationId === notification.id && (
                          <div
                            ref={tooltipRef}
                            className="absolute left-[-150%] top-[-150%] border-2 bg-white p-2 drop-shadow-lg "
                          >
                            <button
                              type="button"
                              onClick={() => {
                                handleEdit(notification)
                                setTooltipNotificationId(null)
                              }}
                              className="block w-full px-2 py-1 text-left hover:bg-normal-grey"
                            >
                              Editar
                            </button>
                            <button
                              type="button"
                              className="block w-full px-2 py-1 text-left hover:bg-red"
                              onClick={() =>
                                handleOpenModal(
                                  'confirm',
                                  removeNotification,
                                  'Estás seguro/a?. Esta acción no se puede deshacer'
                                )
                              }
                            >
                              Borrar
                            </button>
                          </div>
                        )}
                        <button
                          type="button"
                          onClick={() => openOptions(notification.id)}
                          className="w-fit"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="12" cy="6" r="1" />
                            <circle cx="12" cy="12" r="1" />
                            <circle cx="12" cy="18" r="1" />
                          </svg>
                        </button>
                      </div>
                    ]}
                  />
                ))}
              </Table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MainNotifications
