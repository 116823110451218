import { ASSETS_URL } from './icons'

export const TICKETS_STATUS = [
  {
    value: 1,
    name: 'Creado'
  },
  {
    value: 2,
    name: 'En revisión'
  },
  {
    value: 3,
    name: 'Gestionado'
  },
  {
    value: 4,
    name: 'Resuelto'
  }
]

export const TICKET_STATES_INDEX = {
  Creado: 1,
  'En revisión': 2,
  Gestionado: 3,
  Resuelto: 4
}

export const STATUS_COLOR = {
  Creado: 'text-[#9747FF] bg-[#F7F0FF]',
  'En revisión': 'text-[#F0A155] bg-[#FFF2DF66]',
  Gestionado: 'text-[#0E9AFF] bg-[#0E9AFF14]',
  Resuelto: 'text-[#4EB03E] bg-[#34C24014]'
}

export const STATUS_ICONS = {
  Creado: `${ASSETS_URL}/tickets/created.png`,
  'En revisión': `${ASSETS_URL}/tickets/inReview.svg`,
  Gestionado: `${ASSETS_URL}/tickets/managed.png`,
  Resuelto: `${ASSETS_URL}/tickets/solved.png`
}

export const TYPES_OPTIONS = {
  'Pedido / Cambiar destino': 'Cambiar destino',
  'Pedido / Devolución a remitente': 'Devolución a remitente',
  'Indemnizaciones / Entrega con daño': 'Entrega con daño',
  'Otros / Falla en plataforma': 'Falla en plataforma',
  'Otros / Otras consultas': 'Otras consultas',
  'Pedido / Pedido con demora': 'Pedido con demora'
}

export const MAX_DAYS_DIFF = 4
