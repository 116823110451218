import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import { AuthContext } from './contexts/AuthContext'
import Sidebar from './components/Sidebar/Sidebar'
import Login from './pages/Login'
import CompanyConditions from './pages/CompanyConditions'
import DiscountMatrix from './pages/DiscountMatrix'
import RechargeMatrix from './pages/RechargeMatrix'
import AllTickets from './pages/Tickets/AllTickets'
import ManageTicket from './pages/Tickets/ManageTicket'
import QuotingPrice from './pages/QuotingPrice'
import NotFound from './pages/NotFound'
import ROUTES from './constants/routes'
import './App.css'
import Interceptors from './Interceptors'
import CourierPrices from './pages/CourierPrices/CourierPrices'
import DeliveryTimes from './pages/DeliveryTimes'
import Sales from './pages/Sales'
import Dashboard from './pages/Dashboard'
import Approbations from './pages/Approbations'
import PricesDetail from './pages/CourierPrices/PricesDetail'
import ManageCompanies from './pages/ManageCompanies'
import Sale from './pages/Sale'
import AllRefunds from './pages/Refunds/AllRefunds'
import RefundDetail from './pages/Refunds/RefundDetail'
import CreateRefund from './pages/Refunds/CreateRefund'
import Details from './pages/Details'
import Billing from './pages/Billing/Billing'
import BillingDetail from './pages/Billing/BillingDetail'
import NotificationCenter from './pages/NotificationCenter'
import EditBill from './components/Billing/HoldingBills/EditBill'

const App = () => {
  const { auth } = useContext(AuthContext)

  return (
    <Router>
      <Interceptors>
        {auth ? (
          <div className="flex h-full w-full bg-light-grey font-lexend text-ultra-dark-grey">
            <Sidebar />
            <div className="flex h-screen w-full overflow-y-auto">
              <div className="h-full w-full overflow-auto p-0">
              <QueryParamProvider adapter={ReactRouter5Adapter}>
                <Switch>
                  <Route exact path="/" component={CompanyConditions} />
                  <Route exact path={ROUTES.CONDITIONS} component={CompanyConditions} />
                  <Route exact path={ROUTES.BILLINGS} component={Billing} />
                  <Route exact path={ROUTES.BILLINGS_DETAIL} component={BillingDetail} />
                  <Route exact path={ROUTES.DETAILS} component={Details} />
                  <Route exact path={ROUTES.DETAILS_EDIT_BILL} component={EditBill} />
                  <Route exact path={ROUTES.QUOTING_PRICE} component={QuotingPrice} />
                  <Route exact path={ROUTES.DISCOUNT} component={DiscountMatrix} />
                  <Route exact path={ROUTES.RECHARGE} component={RechargeMatrix} />
                  <Route exact path={ROUTES.COMPANIES} component={ManageCompanies} />
                  <Route exact path={ROUTES.SALE} component={Sale} />
                  <Route exact path={ROUTES.TICKETS} component={AllTickets} />
                  <Route path={ROUTES.SHOW_TICKETS} component={ManageTicket} />
                  <Route exact path={ROUTES.REFUNDS} component={AllRefunds} />
                  <Route exact path={ROUTES.REFUND} component={RefundDetail} />
                  <Route exact path={ROUTES.CREATE_REFUND} component={CreateRefund} />
                  <Route exact path={ROUTES.COURIER_PRICES} component={CourierPrices} />
                  <Route exact path={ROUTES.COURIER_PRICES_DETAIL} component={PricesDetail}/>
                  <Route exact path={ROUTES.DELIVERY_TIMES} component={DeliveryTimes} />
                  <Route exact path={ROUTES.SALES} component={Sales} />
                  <Route exact path={ROUTES.NOTIFICATION_CENTER} component={NotificationCenter} />
                  <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
                  <Route exact path={ROUTES.APPROBATIONS} component={Approbations} />
                  <Route path="*" component={NotFound} />
                </Switch>
              </QueryParamProvider>
              </div>
            </div>
          </div>
        ) : (
          <Switch>
            <Route path="*" component={Login} />
          </Switch>
        )}
      </Interceptors>
    </Router>
  )
}

export default App
