import React from 'react'

const NotificationCenterSecondary = () => {
  const coneSVG = () => (
    <div className="h-auto w-16">
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g strokeWidth="0" />
        <g strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="m10.286 1030.4-5.347 18h14.257l-5.346-18z"
          fill="#ecf0f1"
          transform="translate(0 -1028.4)"
        />
        <path d="m4 1047.4-2 2h20l-2-2z" fill="#f39c12" transform="translate(0 -1028.4)" />
        <path
          d="m10.286 1030.4-.891 3h5.346l-.891-3zm-2.089 7-1.197 4h10.136l-1.198-4zm-2.366 8-.892 3h14.257l-.891-3z"
          fill="#e67e22"
          transform="translate(0 -1028.4)"
        />
        <path
          d="m10.286 1030.4-5.347 18h7.129v-18z"
          fill="#bdc3c7"
          transform="translate(0 -1028.4)"
        />
        <path
          d="m10.286 1030.4-.891 3h2.673v-3zm-2.089 7-1.197 4h5.068v-4zm-2.366 8-.892 3h7.129v-3z"
          fill="#d35400"
          transform="translate(0 -1028.4)"
        />
        <path fill="#e67e22" d="M2 1049.4h20v1H2z" transform="translate(0 -1028.4)" />
      </svg>
    </div>
  )
  return (
    <div className="my-32 flex items-center justify-center">
      {coneSVG()}
      Aún no disponible
      {coneSVG()}
    </div>
  )
}

export default NotificationCenterSecondary
