import React from 'react'

const ActionsBar = ({ statusFilters, selectedFilters, setSelectedFilters, rightChildren}) => {
  const handleFilter = (status) => {
    if (selectedFilters.includes(status.value)) {
      setSelectedFilters(selectedFilters.filter((filter) => filter !== status.value))
    } else {
      setSelectedFilters([...selectedFilters, status.value])
    }
  }
  return (
    <div className="mx-10 mt-4 flex justify-between rounded-t border-x border-t bg-white px-3 py-2">
      <div className="flex gap-2 overflow-hidden py-1 text-s">
        <button
          className={`rounded p-2 ${selectedFilters.length > 0 ? '' : 'bg-normal-grey'} hover:bg-ultra-light-grey`}
          onClick={() => setSelectedFilters([])}
          type="button"
        >
          Todos
        </button>
        {statusFilters.map((status) => (
          <button
            key={status.value}
            className={`whitespace-nowrap rounded p-2 ${selectedFilters.includes(status.value) ? 'bg-normal-grey' : ''} hover:bg-ultra-light-grey`}
            onClick={() => handleFilter(status)}
            type="button"
          >
            {status.name}
          </button>
        ))}
      </div>
      {rightChildren}
    </div>
  )
}

export default ActionsBar
