import React, { useEffect, useState } from 'react'
import Table from '../../shared/Table/Table'
import TableRow from '../../shared/Table/TableRow'
import InputForm from '../../shared/Input/InputForm'
import { searchIcon } from '../../../constants/icons'

const HoldingList = ({ holdings, holdingSelection, handleHoldingSelect, handleSelectAll }) => {
  const [isEveryHoldingSelected, setIsEveryHoldingSelected] = useState(false)
  const [holdingsData, setHoldingsData] = useState([{ name: '' }])
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setHoldingsData([...holdings])
  }, [holdings])

  const filteredHoldings = holdingsData?.filter((holding) =>
    holding.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleSelection = (value) => {
    handleHoldingSelect(value)
  }

  const handleSelectAllHoldings = () => {
    setIsEveryHoldingSelected(!isEveryHoldingSelected)
    handleSelectAll(!isEveryHoldingSelected, filteredHoldings)
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div
      className="relative overflow-x-hidden overflow-y-scroll"
      style={{
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
      }}
    >
      <div className="flex w-full items-center justify-center gap-2">
        <div className="mb-2 text-xl">Holdings</div>
        <input
          id="select_all_holdings_checkbox"
          onChange={() => handleSelectAllHoldings()}
          type="checkbox"
        />
        <label className="relative w-full">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <img className="h-4 w-4" alt="icon-input" src={searchIcon} />
          </span>
          <input
            id="holding_search_bar"
            className="w-[98%] rounded-sm border border-medium-gray py-1 pl-8 text-sm placeholder:italic placeholder:text-medium-gray focus:outline-none focus:ring-1 focus:ring-pinflag-dark"
            placeholder="Buscar Holding"
            type="text"
            name="search"
            value={searchTerm || ''}
            onChange={handleSearchChange}
          />
        </label>
      </div>
      <Table headers={['Holding', 'Activo']} maxHeight="max-h-[60vh] mx-0">
        {filteredHoldings.length > 0 ? (
          filteredHoldings.map((company) => (
            <TableRow
              key={company.id}
              onClick={() => {}}
              items={[
                <div className="flex items-center text-xs">{company.name || '-'}</div>,
                <InputForm
                  id={`selected_${company.id}`}
                  size="w-10"
                  name="active"
                  type="checkbox"
                  checked={holdingSelection.includes(company.id)}
                  onChange={() => handleSelection(company)}
                />
              ]}
            />
          ))
        ) : (
          <TableRow items={[<div className="flex items-center text-xs">No holdings found</div>]} />
        )}
      </Table>
    </div>
  )
}

export default HoldingList
