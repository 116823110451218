import React from 'react'
import InputForm from '../../shared/Input/InputForm'
import FormButton from '../../shared/FormButton'
import FormSection from './FormSection'


const LogisticsContacts = ({
  logisticsContacts,
  addLogisticsContact,
  handleContactsChange,
  isEditing
}) => (
  <FormSection sectionTitle="Datos de Logística">
    {logisticsContacts.length <= 1 ? (
      <FormButton
        onClick={addLogisticsContact}
        colSpan="col-span-2"
        text="+ Contacto"
        styles="w-32 py-2"
      />
    ) : null}
    {logisticsContacts.map((logisticsContact, index) => (
      <>
        <InputForm
          label={`Contacto ${index + 1}`}
          id={`logisticsContact${index + 1}`}
          name="email"
          type="text"
          value={logisticsContact.email || ''}
          onChange={(event) => handleContactsChange(event, 'logistics', index)}
          disabled={!isEditing}
        />
        <InputForm
          label={`Telefono ${index + 1}`}
          id={`phoneLogistic${index + 1}`}
          name="phone"
          type="text"
          value={logisticsContact.phone || ''}
          onChange={(event) => handleContactsChange(event, 'logistics', index)}
          disabled={!isEditing}
        />
      </>
    ))}
  </FormSection>
)

export default LogisticsContacts
