import ROUTES from './routes'

export const SIDEBAR_BUTTONS = {
  conditions: {
    text: 'Condiciones Empresas',
    path: ROUTES.CONDITIONS
  },
  billing: {
    text: 'Facturas',
    path: ROUTES.BILLINGS
  },
  discount: {
    text: 'Matriz de Descuento',
    path: ROUTES.DISCOUNT
  },
  recharge: {
    text: 'Matriz de Recarga',
    path: ROUTES.RECHARGE
  },
  sale: {
    text: 'Ventas',
    path: ROUTES.SALE
  },
  companies: {
    text: 'Registro de Empresas',
    path: ROUTES.COMPANIES
  },
  courierPrices: {
    text: 'Tarifas Courier',
    path: ROUTES.COURIER_PRICES
  },
  deliveryTimes: {
    text: 'Tiempos de Envío',
    path: ROUTES.DELIVERY_TIMES
  },
  tickets: {
    text: 'Ver solicitudes',
    path: ROUTES.TICKETS
  },
  refunds: {
    text: 'Indemnizaciones',
    path: ROUTES.REFUNDS
  },
  quoting: {
    text: 'Cotizador',
    path: ROUTES.QUOTING_PRICE
  },
  sales: {
    text: 'Ventas',
    path: ROUTES.SALES
  },
  dashboard: {
    text: 'Dashboard',
    path: ROUTES.DASHBOARD
  },
  permissions: {
    text: 'Permisos',
    path: ROUTES.PERMISSIONS
  }
}

export const PINFLAG_PLAN_NAMES = [
  'pyme1',
  'pyme2',
  'medium1',
  'medium2',
  'large1',
  'large2',
  'extralarge1',
  'extralarge2'
]

export const WITHOUT_PLAN = 'Base'

export const BILLING_PLAN_NAMES = [
  'only map',
  'points',
  'map & points',
  'map, points & multicourier',
  'full'
]

export const DEFAULT_COURIER = 'shippify'

export const DELIVERY_METHODS = [
  {id: 'homeDelivery', name: 'Domicilio'},
  {id: 'clickAndCollect', name: 'Click & Collect'},
  {id: 'storePickup', name: 'Punto Propio'}
]

export const DEVICES = [
  {id: 'desktop', name: 'Desktop'},
  {id: 'mobile', name: 'Celular'}
]
