import { React } from 'react'

import { chevron2 } from '../../constants/icons'

const Pagination = ({ page, setPage, pageSize, setPageSize, itemsCount, xs }) => {
  const maxPage = Math.ceil(itemsCount / pageSize) || 1

  const changePage = (newPage) => {
    if (newPage > 0 && newPage <= maxPage) {
      setPage(newPage)
    }
  }

  const handleCorrectPage = () => {
    if (page > maxPage) {
      setPage(1)
    }
  }

  handleCorrectPage()

  return (
    <div
      className={xs ? 'flex flex-wrap gap-4 self-end text-xs' : 'my-4 flex flex-wrap gap-4 text-sm'}
    >
      <div className="self-center text-dark-grey">
        <span>Mostrar</span>
        <select
          className="mx-2 rounded border px-1"
          onChange={(e) => setPageSize(e.target.value)}
          value={pageSize}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
        </select>
        <span>por página</span>
      </div>
      <div className="my-auto text-normal-grey">|</div>
      <div className="flex gap-1 self-center">
        <button
          className={`flex items-center self-center
          p-1.5 font-semibold text-dark-grey ${page === 1 ? 'opacity-60' : ''}`}
          type="button"
          onClick={() => changePage(1)}
          disabled={page === 1}
        >
          <img alt="icon" src={chevron2} className="h-auto w-1.5" />
          <img alt="icon" src={chevron2} className="h-auto w-1.5" />
        </button>
        <button
          className={`flex items-center self-center
          p-1.5 font-semibold text-dark-grey ${page === 1 ? 'opacity-60' : ''}`}
          onClick={() => changePage(page - 1)}
          type="button"
          disabled={page === 1}
        >
          <img alt="icon" src={chevron2} className="h-auto w-1.5" />
        </button>
        <div className="px-2">
          <span className="rounded border bg-white px-2 py-0.5">{page}</span> de {maxPage}
        </div>
        <button
          className={`flex items-center self-center
          p-1.5 font-semibold text-dark-grey ${page === maxPage ? 'opacity-60' : ''}`}
          onClick={() => changePage(page + 1)}
          type="button"
          disabled={page === maxPage}
        >
          <img alt="icon" src={chevron2} className="h-auto w-1.5 rotate-180" />
        </button>
        <button
          className={`flex items-center self-center
          p-1.5 font-semibold text-dark-grey ${page === maxPage ? 'opacity-60' : ''}`}
          onClick={() => changePage(maxPage)}
          type="button"
          disabled={page === maxPage}
        >
          <img alt="icon" src={chevron2} className="h-auto w-1.5 rotate-180" />
          <img alt="icon" src={chevron2} className="h-auto w-1.5 rotate-180" />
        </button>
      </div>
    </div>
  )
}

export default Pagination

