export const PAYMENT_NOTIFICATION_MOCK = [
	{id: 1, name: 'Aviso de Factura', show_after:0, banner: true, mail: true, pop_up: true, whatsapp: true, active: true },
	{id: 2, name: '1 Recordatorio', show_after:15, banner: false, mail: true, pop_up: false, whatsapp: true, active: true },
	{id: 3, name: '2 Recordatorio', show_after:20, banner: true, mail: true, pop_up: false, whatsapp: true, active: true },
	{id: 4, name: '3 Recordatorio', show_after:25, banner: true, mail: true, pop_up: true, whatsapp: true, active: true },
	{id: 5, name: 'Último Recordatorio', show_after:27, banner: true, mail: true, pop_up: true, whatsapp: true, active: true },
	{id: 6, name: 'Aviso de Suspensión', show_after:30, banner: true, mail: true, pop_up: true, whatsapp: true, active: true },
	{id: 7, name: 'Último Aviso', show_after:35, banner: true, mail: true, pop_up: true, whatsapp: true, active: true },
	{id: 8, name: 'Cuenta Suspendida', show_after:45, banner: true, mail: true, pop_up: true, whatsapp: true, active: true }
]

export const GENERAL_NOTIFICATIONS_TYPES = [
	{name: 'Main'},
	{name: 'Banner'},
	{name: 'PopUp'},
	{name: 'Mail'}
]
