import React, { useState } from 'react'

import { downArrow } from '../../../constants/icons'
import { isSubstring } from '../../../utils/strings'
import SearchBarBilling from '../SearchBarBilling'

const CheckColumn = ({ isOpen, open, name, checkList, handleCheck, checkState }) => {
  const [searchInput, setSearchInput]  = useState('')
  const [currentCheckList, setCurrentCheckList] = useState([])

  useState(() =>{
    setCurrentCheckList(checkList)
  },[])

  const searchResult = searchInput.length > 0 ?
  currentCheckList.filter(item => isSubstring(item, searchInput))
  : currentCheckList

  return (
    <div>
      <button onClick={open} type="button" className="flex w-full justify-between font-medium">
        <div className="flex gap-2">
          {name}
          {checkState.filter((check) => check).length > 0 && (
            <div className="rounded-md bg-ultra-dark-grey px-1.5 py-0.5 text-xs text-white">
              {checkState.filter((check) => check).length}
            </div>
          )}
        </div>
        <img src={downArrow} alt="abrir" className={`w-2.5 ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {isOpen && (
        <div className="mx-2 mt-5">
          <SearchBarBilling
            searchInput={searchInput}
            setSearchbarInput={setSearchInput}
            setShouldFetch={() => {}}
          />
          {searchResult.map((checkItem) => (
            <label
              key={checkItem}
              htmlFor={checkItem}
              className="mt-2 flex cursor-pointer flex-nowrap whitespace-nowrap text-s text-medium-dark-grey"
            >
              <input
                className="mr-1 cursor-pointer"
                type="checkbox"
                name={checkItem}
                id={checkItem}
                value={checkItem}
                onChange={() => handleCheck(checkItem)}
                checked={checkState[checkItem]}
              />
              {checkItem}
            </label>
          ))}
        </div>
      )}
    </div>
  )
}

export default CheckColumn
